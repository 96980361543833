import React, { useState, useRef, useEffect } from "react";
import { TweenMax, TimelineMax, Power3 } from "gsap";
import { Tween } from 'react-gsap';
import { Row } from 'components/row/Row';
import ReactDOM from 'react-dom';
import s from './Clients.scss';


export class Clients extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      activeIndex: 0,
    };  


  }


  componentDidMount(){
  


  }

  prevSlide(){
  }

  componentWillUnmount(){
  }

  render() {

    return (

        <div className="hide_on_mobile">

            <Row style={{maxHeight: '54vw'}}>

              {this.props.logos.map((logo, index) => (
                <img
                  key={index}
                  src={`https://futurebakery.cz/grav/user/pages/bakery/pages/about-us/${logo.logotype}`}
                  className={`whom_${((index % 3) + 1).toString().padStart(2, '0')}`}
                />
              ))}  

            </Row>  

        </div>    

    );

  }
}

